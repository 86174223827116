import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    graniteBayImage: file(relativePath: { eq: "granite-bay.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithCarInNeighborhoodImage: file(
      relativePath: { eq: "locksmith-car-in-neighborhood.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function GraniteBayPage({ data }) {
  return (
    <MainLayout
      title="916-995-0135 | Local Mobile Locksmith in Granite Bay, CA"
      description="Offering lock and key solutions for key replacement, transponder key programming, expert lock picking, lockouts and more in Granite Bay. Licensed & Insured."
      offset
    >
      <ContactHeading background={data.graniteBayImage.childImageSharp.fluid}>
        <h1 className="text-center">Locksmith Granite Bay</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Locksmith in Granite Bay, CA
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section" className="pb-0 pb-md-5">
        <h2>Granite Bay's Leading Locksmith</h2>
        <Row>
          <Col xs={12} md={{ span: 6, order: 2 }} lg={8} xl={9}>
            <p className="indent">
              As one of the leading locksmith companies, we have been providing
              premium residential, commercial, automotive and emergency
              locksmith services for the community of Granite Bay, CA and the
              surrounding areas for over a decade.
            </p>
            <p className="indent">
              Our team of locksmith technicians are prepared and trained to
              resolve all of your locksmith needs whether you are looking to
              rekey your home, need help with a car lock out want to upgrade
              your office security system to name a few.
            </p>
            <p className="indent">
              We strive to provide you with the highest customer and locksmith
              service along with competitive pricing. We are a mobile locksmith
              company that is available 24/7, seven days a week and we work
              through any holiday so you can always depend on Swift Locksmith to
              help you with any locksmith need you have at any time.
            </p>
          </Col>
          <Col
            xs={12}
            md={{ span: 6, order: 1 }}
            lg={4}
            xl={3}
            className="col-sm-pad"
          >
            <Img
              fluid={data.locksmithCarInNeighborhoodImage.childImageSharp.fluid}
              alt="locksmith-car-in-neighborhood"
            />
          </Col>
        </Row>
      </Container>
      <section className="pt-0 pt-sm-5">
        <div className="background-content bg-dark text-white">
          <Container>
            <h2 className="text-center">What We Offer</h2>
            <Row>
              <Col xs={12} md={6}>
                <h3 className="text-primary">Residential Services</h3>
                <p>
                  Locked out of your home? Looking to replace your locks? Our
                  locksmiths are on standby 24 hours a day, 7 days per week to
                  assist you. We offer high security locks, deadbolts,
                  cylinders, lock repair and keypad door entry systems as some
                  of our residential services. We understand that your home
                  ought to be the most secure spot on the planet and we want to
                  make sure you keep it secured.
                </p>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="text-primary">Automotive Services</h3>
                <p>
                  Our professional locksmiths are trained in everything when it
                  comes to automotive locksmith needs. Whether you need a key
                  replacement or repair, ignition key duplication, assistance
                  with unlocking your vehicle, our locksmith technicians got you
                  covered. With 24/7 emergency automotive locksmith services,
                  you can always count on Swift Locksmith to get you back in
                  your car and back to your normal routine!
                </p>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="text-primary">Commercial Services</h3>
                <p className="mb-md-0">
                  We offer a variety of commercial locksmith services for
                  Granite Bay businesses to strengthen the security of your
                  business. Our skilled team of locksmith technicians provide
                  customized and quality commercial locksmith solutions for
                  businesses all over Granite Bay, CA. Our expert team is highly
                  trained in every area of the installation, repair and service
                  to ensure that your company will continue to operate
                  proficiently.
                </p>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="text-primary">24/7 Emergency Services</h3>
                <p className="mb-0">
                  When you get locked out at the least convenient time, it’s
                  good to know that someone is close by. As disappointing as
                  this circumstance might be, when you’re in this situation,
                  call Swift Locksmith to ensure that your locksmith needs are
                  resolved quickly and efficiently. Our locksmith technicians
                  are always ready, on standby by to help you when you need us
                  most, no matter what day or time it is. We are committed to
                  providing you the best, reliable and affordable services at
                  all times.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="mb-5">
        <h2>Trusted & Reliable Granite Bay Locksmith</h2>
        <p className="indent mb-0">
          Swift Locksmith in Granite Bay, CA offers assurance for people who are
          looking to secure their property as well as emergency response for
          those who need to get back into their houses, offices, or vehicles.
          Our team of professionals are friendly, knowledgeable and experienced
          to unlock and install any kind of lock needed. Our clients always come
          first and our locksmith company is built on a reputation of honesty,
          expertise and providing only the best customer service to all of our
          clients. If you are looking for reliable locksmith services, quality
          materials and reasonable prices, call us today and we will be happy to
          assist you!
        </p>
      </Container>
    </MainLayout>
  )
}

export default GraniteBayPage
